﻿@import '../../../../../Common/src/scss/components/react/MobileFiltersMenu.scss';

.MobileFiltersMenu {
    background: $black;
    color: $white;

    &__list > ul > li {
        background: inherit;
        color: var(--primary);

        button.selected {
            text-decoration: underline;
            color: var(--primary) !important;
        }
        .Selector:not(.button) {
            color: $white;
        }
    }

    &__btnBack {
        text-transform: none;
    }

    .MobileFiltersMenu__btnApply {
        font-weight: 400;
        color: black;

        &:disabled {
            background-color: $gray-primary;
            color: $gray-ghost;
        }
    }
}
