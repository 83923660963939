@import '../../../../../Common/src/scss/components/react/StoreLocator.scss';

.StoreLocator {
    input.storeLocatorSearchInput.pac-target-input {
        color: $dark;
        background-color: var(--light);
        border: 2px solid #000;

        @include media-breakpoint-down('md') {
            width: 23em;
        }
    }
}
