﻿.ProductListView {
    position: relative;
    padding: 1.5rem;

    @include media-breakpoint-down(md) {
        margin-top: 4rem;
        padding: 0.5rem;
    }

    @include media-breakpoint-down(sm) {
        border: 1px solid var(--primary);
    }

    .ProductBadge {
        margin: 0;
        .badge {
            margin: 0;
            padding: 0;
        }
    }

    .ProductPrice {
        margin: 0;
        p {
            font-size: inherit;
        }
    }

    &__title {
        @include media-breakpoint-down(lg) {
            position: absolute;
            top: -2.5rem;
            left: 0;
        }

        text-transform: uppercase;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .btn {
            position: relative;
            color: inherit;
            margin-right: -1rem;
            padding: 1rem 1rem;
            font-size: 1.2rem;
        }
    }

    &__input {
        max-width: 40rem;
        margin-right: 1.275rem;
        margin-left: auto;
    }

    &__list {
        text-align: left !important;

        .ProductDetailCard {
            transition: 300ms max-height;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            align-items: end;

            &:not(.expanded) {
                .ProductDetailCard__name h6 {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

            &__container {
                padding-bottom: 2em;

                &__displayonly {
                    transition: height 0.3s ease-in-out;
                    height: 0px;
                    overflow: hidden;
                    
                    & > div {
                        display: flex;
                        flex-direction: column;
                        gap: 0.5rem;
                    }

                    &__row {
                        display: flex;
                        align-items: flex-end;
                        gap: 1rem;
                        
                        &__col {
                            flex: 1 1 0;
                            font-weight: bold;
                            padding-top: 1em;
                            text-transform: uppercase;
                        }

                        &__cell {
                            flex: 1 1 0;
                            line-height: 1em;
                            span {
                                padding: 0.25em 0;
                            }
                        }
                    }

                    &__toggle {
                        cursor: pointer;
                        color: var(--primary);
                    }
                }
            }

            &:not(:last-child) {
                border-bottom: 1px solid var(--primary);
            }
            &__name {
                width: 100%;
                justify-content: space-between;
                padding: 1rem 0;
                position: relative;

                h6 {
                    color: var(--primary);
                    margin: 0;
                    max-width: calc(100% - 3rem);
                    flex-grow: 1;
                }

                .gallery-btn {
                    align-self: center;
                    font-size: 1.25rem;
                    padding: 0.75rem;
                    border-radius: 15%;
                }

                .ProductDetailCard__btn {
                    display: flex;
                    align-items: flex-start;
                    right: -1em;
                    top: 0;
                    bottom: 0;
                    padding: 1rem 0 0 1rem;
                    font-size: 0.875rem;
                    background: none;
                    border: none;
                    color: var(--primary);

                    &.collapsed {
                        padding-top: 0;
                    }
                }
            }

            &__input {
                height: 3.5em;
                .num-input {
                    padding-left: 0 !important;
                }
                .num-input + div > div:last-child {
                    padding-right: 0 !important;
                }
                > div,
                > div > div {
                    max-height: 100%;
                }

                button.active {
                    background-color: var(--primary) !important;
                }
            }

            table {
                table-layout: fixed;
                max-width: 100%;

                tr.clickable {
                    cursor: pointer;
                }

                th,
                td {
                    line-height: 1em;
                    vertical-align: bottom;
                    span {
                        padding: 0.25em 0;
                    }
                }
                th {
                    font-weight: bold;
                    padding-right: 2em;
                    padding-top: 1em;
                    text-transform: uppercase;
                }
                td {
                    font-weight: 200;
                }
            }
        }
    }

    .ProductListTable {
        border: 1px solid var(--primary);
        &.Grid {
            .Grid {
                &__cell {
                    padding: 0.5rem 0;

                    &--header {
                        font-weight: bold;
                        text-transform: uppercase;
                        padding: .5rem 0;
                    }

                    &--sticky-left {
                        &:not(.Grid__cell--header) {
                            .header-column {
                                @include media-breakpoint-up(xl) {
                                    max-width: 33vw;
                                }
                            }
                        }
                    }

                    .gallery-btn {
                        padding: 0.33rem 0.75rem;
                        align-self: center;
                        border-radius: 10%;
                    }

                    .NotificationSubscription {
                        width: 125px;
                    }

                    .NumberInput-btn {
                        left: unset;
                        margin: 0 1rem 0 0.5rem;
                    }

                    .ProductPrice {
                        padding-right: 0.5rem;
                        align-self: center;
                    }
                }
            }
        }
    }
}
