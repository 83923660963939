﻿@import '../../../../../Common/src/scss/components/react/SearchResults.scss';

.heading-space {
    height: 8rem;
    background: $dark;

    @include media-breakpoint-down(md) {
        height: 7rem;
    }
    @include media-breakpoint-down(xs) {
        height: 5rem;
    }
}

.SearchResults {
    &.theme-dark {
        .heading-backdrop {
            background: $dark;
        }
        .result-count {
            color: $light;
        }
        .SearchBar {
            width: 100%;
        }

        .SearchResultTabs .nav-tabs a {
            color: $light !important;
            &.active {
                color: var(--primary) !important;
            }

            .Icon {
                color: var(--primary);
            }
        }

        .extra-container {
            .filter-drawer-link {
                a {
                    color: $light;
                }
            }
        }
    }

    .result-count {
        font-size: 0.875rem;
        margin-top: 0.15em;
    }

    .SearchResultProduct {
        .CatalogCard {
            &__links {
                flex-direction: column;
                li {
                    margin-left: 0px;
                }
            }
        }
    }
}
