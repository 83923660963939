﻿@import '../../../../../Common/src/scss/components/react/ProductListView.scss';

.ProductListView {
    .ProductBadge {
        .badge {
            font-family: var(--secondary-font);
            letter-spacing: 0.1em;
            font-weight: bold;
            font-size: 0.725rem;
            border: none;
            color: $dark;
            background: var(--primary);
            padding: 0.25em 0.25em 0 0.25em;
        }
    }

    .ProductPrice {
        p {
            color: $dark;
            font-family: var(--tertiary-font);
            font-weight: 400;
            letter-spacing: 0.1em;
        }
    }

    &__title {
        font-family: var(--primary-font);
        font-size: 1.2rem;
        font-weight: bold;
        color: $dark;
        letter-spacing: 0.1em;

        .btn {
            color: var(--secondary);
        }
    }

    &__list {
        .ProductDetailCard {
            font-size: 0.725rem;

            &__name {
                h6 {
                    font-size: 0.975rem;
                    font-family: var(--primary-font);
                    letter-spacing: 0.15em;
                    color: $dark;
                }
            }

            .NumberInput-btn {
                font-size: 12px !important;
                position: absolute;
                padding: 12px 2px;
            }

            .NumberInput {
                font-size: 14px !important;
                height: 2.625rem;
            }

            .oos {
                .btn:not(.NotificationSubscription__notify-link) {
                    background: $gray-medium !important;
                    color: $dark !important;
                    opacity: 1 !important;
                    pointer-events: none;
                    border: none !important;
                }
            }

            table {
                th,
                td {
                    font-size: 0.725rem;
                    letter-spacing: 0.1em;
                }

                th {
                    font-family: var(--secondary-font);

                    @include media-breakpoint-down(md) {
                        padding-top: 0.25rem;
                        vertical-align: top;
                    }
                }

                td {
                    font-family: var(--tertiary-font);

                    @include media-breakpoint-down(md) {
                        padding-top: 0.25rem;
                    }
                }
            }

            &__container__displayonly {
                &__toggle {
                    th {
                        text-decoration: underline;
                    }

                    color: $dark;
                }

                &__row__col {
                    font-family: var(--secondary-font);
                    padding: 0.25em 0;
                }
            }

            .gallery-btn {
                line-height: 1rem;
            }
        }
    }

    .ProductListTable {
        &.Grid {
            @include branded-scroll-x($gray-light, var(--primary), 0.75rem);

            .Grid {
                &__cell {
                    font-family: var(--tertiary-font);
                    font-size: 0.85rem;

                    &--even {
                        &:not(.Grid__cell--header) {
                            background-color: $light;
                        }
                    }

                    &--header {
                        font-family: var(--secondary-font);
                        letter-spacing: 0.1em;
                    }

                    &--odd {
                        &:not(.Grid__cell--header) {
                            background-color: $gray-light;
                        }
                    }

                    .gallery-btn {
                        font-size: 1.25rem;
                        margin-right: 1.2rem;
                    }
                }
            }
        }
    }
}
