﻿@import '../../../../../Common/src/scss/components/react/CartSummary.scss';

.CartSummary {
    .payment-method-container > .payment-method-contents > .payment-method {
        &.ApplePay > img {
            filter: invert(100%);
        }
        &.AndroidPay > img,
        &.GooglePay > img {
            filter: brightness(1.5);
        }
        &.AmazonPay > img {
            filter: brightness(3);
        }
        &.PayPal > img {
            filter: brightness(1.5);
        }
    }
}
