/*
 ==== Color Palette ==========================
*/
// Override Bootstrap defaults
$red: #c40030;
$yellow: #ffc627;
$orange: #f5a52d;
$yellow-layer-1: rgba(196, 0, 48, 0.5);
$white: #fff;
$black: #000;
$gray-primary: #212221;

$primary: $yellow;
$primary-layer-1: $yellow-layer-1;
$secondary: $black;
$tertiary: $gray-primary;
$light: $white;
$dark: $black;

// Lew's custom colors
// Question,  should we override bootstrap grays?
//   dark -> gray-900
//   primary -> gray-800
//   secondary -> gray-600
//   ghost -> gray-100
$gray-dark: #1d1b1c;
$gray-secondary: #78777a;
$gray-ghost: #f3f6f6;
$semi-dark: #232a2d;
$gray-light: #f2f1f1;
$gray-medium: #d4d4d4;
$gray-mix: #a5a5a5;
$checkbox-fill-color: $primary;

$info: $primary;
$success: $light;
$warning: $yellow;
$danger: $red;

$text-highlight: rgba(215, 239, 255, 0.75);
/*
 ==== Fonts ==============================
*/
$primary-font: 'Roboto', sans-serif;
$secondary-font: 'alternate-gothic-no-2-d', sans-serif;
$tertiary-font: 'Roboto-Slab';
$font-family-base: $primary-font !default;
$font-weight-bolder: 900 !default;
$input-btn-font-size: 1.188rem;
$input-btn-font-size-sm: 1rem;
$input-btn-font-size-lg: 1.438rem;

// Default css variable fonts
:root {
    --primary-font: #{$primary-font};
    --secondary-font: #{$secondary-font};
    --tertiary-font: #{$tertiary-font};
    --font-family-base: #{$primary-font};
    --primary-contrast: #{$dark};
    --secondary-contrast: #{$light};
    --checkbox-fill-color: var(--primary);
    --primary-layer-1: rgba(196, 0, 48, 0.5);
    --link-color: #{$black};
    --btn-font-size: #{$input-btn-font-size};
    --btn-font-size-lg: #{$input-btn-font-size-lg};
    --btn-font-size-sm: #{$input-btn-font-size-sm};
}

$font-size: 1.2rem; // Assumes 16px base * 1.2 = 19ish
$font-size-base: $font-size;
$font-size-sm: 0.875rem;
$line-height-base: 1.4;
$letter-spacing: 1.5px;
$letter-spacing-1: 1px;
$letter-spacing-2: 2px;
$enable-responsive-font-sizes: true !default;

$h1-font-size: 62px;
$h2-font-size: 53px;
$h3-font-size: 45px;
$h4-font-size: 38px;
$h5-font-size: 30px;
$h6-font-size: 23px;

$h1-mobile-font-size: 53px;
$h2-mobile-font-size: 45px;
$h3-mobile-font-size: 38px;
$h4-mobile-font-size: 30px;
$h5-mobile-font-size: 23px;
$h6-mobile-font-size: 19px;

$headings-font-weight: 900 !default;

$display1-size: 120px;
$display2-size: 90px;
$display3-size: 38px;
$display4-size: 19px;

$display1-weight: 900;
$display2-weight: 900;
$display3-weight: 900;
$display4-weight: 900;

/*
 ==== Links  ==============================
*/
$link-color: $black;
//$link-decoration: underline !default;
/*
 ==== Navigation  ==============================
*/
$navbar-dark-active-color: $white;
$navbar-dark-hover-color: $yellow;
$navbar-dark-color: $primary;

$nav-item-vertical-padding: 0.45rem;
/*
 ==== Buttons  ==============================
*/
$input-btn-font-family: 'Roboto', sans-serif !default;
$btn-font-weight: 900;
$btn-border-radius: 0px;
$btn-border-radius-lg: 0px;
$btn-border-radius-sm: 0px;
/*
 ==== Input  ==============================
*/
$input-font-size: 24px !default;
$input-border-color: $black;
$input-border-width: 2px;
$input-border-radius: 0px;
$input-border-radius-lg: 0px;
$input-border-radius-sm: 0px;
/*
 ==== Alerts  ==============================
*/
$alert-border-radius: 0px;
$alert-border-width: 2px !default;
$alert-border-level: 0 !default;
/*
 ==== Forms  ==============================
*/
$enable-validation-icons: false;
$form-feedback-valid-color: $black;

$breadcrumb-divider: quote('>');
