﻿@import '../../../../Common/src/scss/blocks/_shopbycarousel.scss';
.shopby_carousel {
    &__panels {
        &__panel {
            &__slide {
                &__shopbtn {
                    padding: 0.75rem;
                }

                &__title {
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }
        }
    }

    .SvgIcon {
        color: var(--primary);
    }

    &__title {
        font-size: 3.2rem !important;
    }
}

/*
.btn, .StoreLocator__list .LocatorList__filters__radius-select__button, .ScrollToTopFab
{
    margin-left: 79px;
}*/
