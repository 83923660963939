﻿@import '../../../../../Common/src/scss/components/react/ProductView.scss';

.ProductView {
    .divider {
        @extend .mb-2;
    }

    .ProductTitle {
        p {
            @include font-size(45px);
            letter-spacing: -0.5px;
            line-height: 44px;
            text-transform: uppercase;
            font-weight: $font-weight-bolder;
            font-family: var(--primary-font);
            margin-bottom: 0.5rem;
        }
    }

    .ColorSelector,
    .RadioSelector,
    .CheckboxSelector,
    .DropdownMenuSelector {
        p {
            font-weight: $font-weight-bolder;
            @extend .paragraph-3;
        }

        .paragraph-3 {
            letter-spacing: 0.0157em;
        }
    }

    @include media-breakpoint-down(sm) {
        .ProductTitle {
            p {
                @include font-size(23px);
                letter-spacing: 0;
                line-height: 22px;
            }
        }
    }
}
