﻿a.wysiwyg-link {
    /* Some links from Epi's WYSIWYG editor are wrapped in
       a <u /> tag. Rather than re-styling the nested tag,
       remove all styles and have it inherit, so we can
       don't need to duplicate styling rules. */
    u {
        text-decoration: none;
        font-weight: inherit;
    }

    @media (hover: hover) {
        &:hover {
            font-weight: bold;
            text-decoration-color: var(--primary);
            text-decoration-thickness: 2px;
            cursor: pointer;
            color: var(--secondary);
        }
    }

    &,
    &:visited,
    &:active,
    &:focus {
        font-weight: bold;
        text-decoration-color: var(--primary);
        text-decoration-thickness: 2px;
        cursor: pointer;
        color: var(--secondary);
    }
}

a.bold {
    font-weight: 900;
}

.a-light,
.a-dark {
    padding: 0.5rem 0.5rem;
    font-family: var(--tertiary-font);
    @include font-size(16);
    letter-spacing: 0.25px;
    border: 2px solid transparent;
}

@media (hover: hover) {
    .a-light:hover {
        border-bottom: 2px solid $black;
        text-decoration-line: none;
    }

    .a-dark:hover {
        border-bottom: 2px solid var(--primary);
        text-decoration-line: none;
        color: var(--primary);
    }
}

.a-dark:focus {
    border: 2px solid $white;
    color: $white;
}

.a-light:focus {
    border: 2px solid $black;
}

.a-light:active,
.a-dark:active {
    border: 2px solid transparent;
}

.a-dark {
    color: var(--primary);
}
