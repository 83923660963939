﻿@import '../../../../../Common/src/scss/components/react/PureTip.scss';

.PureTip {
    &__content {
        font-size: 1.1rem;
        &__dismiss {
            font-size: 0.875rem;
        }
    }

    &.danger {
        .PureTip__content {
            border: 1px solid $danger;
            background: $light;
            color: $danger;
        }
    }
}
