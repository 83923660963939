﻿@import '../../../../../Common/src/scss/components/react/CheckoutSummary.scss';

.CheckoutSummary {
    background: $light;
    color: $dark;

    &__Divider {
        border-color: $dark;
    }

    &__LineItems {
        .card-header {
            button.btn {
                color: $dark;
            }
        }
    }
}

.MultiPageCheckout.dark {
    .CheckoutSummary {
        background: $black;
        color: $white;

        &__Divider {
            border-color: $white;
        }

        &__LineItems {
            .card-header {
                button.btn {
                    color: $white;
                }
            }
        }
    }
}
