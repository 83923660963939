.productfacettableblock {
    &__Header {
        display: flex;
        align-items: flex-end;
        margin-left: 3rem;
        min-height: 4rem;

        &.overlap {
            position: absolute;
            top: -175px;
            left: 50px;
            margin-left: unset;
            mix-blend-mode: multiply;

            @include media-breakpoint-down(lg) {
                top: -200px;
            }

            @include media-breakpoint-down(md) {
                top: -155px;
            }

            @include media-breakpoint-down(sm) {
                top: -110px;
                left: 30px;
            }
        }

        &__Title {
            text-transform: uppercase;
            line-height: 1em;

            @include media-breakpoint-down(lg) {
                line-height: 0.85em;
            }
        }

        &__Subtitle {
            display: flex;
            flex-direction: column;
            text-transform: uppercase;
            text-align: left;
            line-height: 0.85em;
            padding: 0 0 1.4rem 0.75rem;

            @include media-breakpoint-down(lg) {
                flex-direction: row;
                padding: 0;

                &-row2 {
                    margin-left: 1.15rem;
                }
            }

            @include media-breakpoint-down(md) {
                &-row2 {
                    margin-left: 1rem;
                }
            }

            @include media-breakpoint-down(sm) {
                &-row2 {
                    margin-left: 0.85rem;
                }
            }
        }

        @include media-breakpoint-down(lg) {
            flex-direction: column;
            align-items: flex-start;
            margin-left: 2rem;
        }
    }

    .ProductListView {
        margin-top: 1rem !important;
    }

    .noOverlap {
        margin-top: 0 !important;
    }
}
