.SvgIcon.icons.icon-paypal-large {
    filter: brightness(1.25);
}

.SvgIcon.icons {
    .icon-amazon-pay-large,
    .icon-apple-pay-large,
    .icon-google-pay-large,
    .icon-apple-pay-large {
        color: $light;
    }
}

svg.icons.icon-logo-alt {
    width: 3.67em;
    display: none;
}
svg.icons.icon-logo {
    width: 1.2em;
}
svg.icons.icon-bars {
    width: 1.5em;
}
