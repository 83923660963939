﻿@import '../../../../../Common/src/scss/components/react/ProductGridView.scss';

.ProductGridView {
    &__title {
        font-family: var(--primary-font);
        font-size: 19px !important;
        @include media-breakpoint-down(sm) {
            font-size: 16px !important;
        }
        font-weight: 600;
        letter-spacing: 0.13em;
        line-height: 1em;
    }

    .ProductGridCell {
        &__container {
            .ProductInput {
                .row {
                    div {
                        height: 100%;
                        button {
                            height: 2.5rem;
                        }
                    }
                }
            }
        }
    }
}
