﻿.social {
    &__link {
        line-height: 36px;
        font-size: 36px;
        display: inline-flex;
        align-items: center;
        color: $white;

        &:not(:first-child) {
            margin-left: 8px;
        }
    }

    &__icon .SvgIcon {
        width: 1em;
        color: $white;
        margin-bottom: 2px;
    }

    &__icon:hover {
        color: white;
    }
}

@include media-breakpoint-down(xs) {
    .social {
        &__link {
            &:not(:first-child) {
                margin-left: 1px;
            }
        }
    }
}


