@import '../../../../../Common/src/scss/components/react/LanguageSelector.scss';

.LanguageSelector {
    button {
        font-size: 0.8em;
        &.dropdown-toggle {
            color: $light;
            background: $dark;
        }
        span {
            height: 0.8em;
        }
    }
}
