﻿@import '../../../../../Common/src/scss/components/react/ProductInput.scss';

.ProductInput {
    > .row {
        align-items: stretch;
        max-height: 2.65rem;
        min-height: 2.65rem;
    }

    input {
        width: 100%;
        height: auto;
    }

    button {
        height: 100%;
    }

    button.buy-now,
    button.buy-now.active {
        background: color-mix(in srgb, var(--primary), var(--primary-layer-1) 20%) !important;
    }
}
