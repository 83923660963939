﻿@import '../../../../../Common/src/scss/components/react/Modal.scss';

.Modal {
    &.Modal--dark {
        .modal-content {
            background: $gray-dark;
            color: var(--primary);
            box-shadow: 0.5em 0.5em $dark, 0.5em -0.5em $dark, -0.5em 0.5em $dark, -0.5em -0.5em $dark;
            border: 1px solid var(--primary) !important;

            .btn-list {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                .btn {
                    font-size: 1rem;
                    padding: 1em;
                }

                @include media-breakpoint-down(xs) {
                    flex-direction: column-reverse;
                    > button {
                        width: 100%;
                    }
                }
            }

            h6 {
                font-size: 1.8rem;
            }

            p {
                font-size: 1rem;
            }

            .modal-footer {
                border-top-color: var(--primary);
            }
        }
    }
}
