﻿@import '../../../../../Common/src/scss/components/react/CatalogCard.scss';

.CatalogCard {
    .subheader {
        @include font-size(19px);
        line-height: 0.8em;
    }

    &__flag {
        > span {
            font-weight: 900;
        }
    }

    &__prices {
        justify-content: center;
        flex-direction: column;
    }

    &__links {
        justify-content: center;

        li span {
            font-weight: bold;
        }
    }

    .ProductBadge {
        @extend .d-flex;
        @extend .justify-content-center;
    }

    @include media-breakpoint-down(lg) {
        &__links {
            flex-direction: column;

            &__links {
                margin-left: 0px;
            }
        }
    }

    @include media-breakpoint-down(md) {
        text-align: center;

        &__links {
            flex-direction: column;

            li span {
                @include font-size(14px);
            }
        }
    }

    @include media-breakpoint-down(sm) {
        &__links {
            li span {
                @include font-size(14px);
            }
        }
    }
}
