@import '../../../../Common/src/scss/blocks/_keyfeatures.scss';

.keyfeaturesblock {
    .KeyFeatures {
        &__Header {
            color: $gray-mix;
            mix-blend-mode: multiply;
            font-size: 5rem;
            font-weight: 900;

            @include media-breakpoint-down(lg) {
                font-size: 4rem;
            }

            @include media-breakpoint-down(sm) {
                font-size: 2.9rem;
            }
        }

        &__features {
            &__bullet {
                &__text {
                    ul li {
                        font-family: var(--secondary-font);
                        text-transform: uppercase;
                        font-size: 1.225em;
                        color: $dark;
                        letter-spacing: 0.1em;

                        @include media-breakpoint-down(lg) {
                            font-size: 1.1em;
                        }

                        @include media-breakpoint-down(sm) {
                            font-size: 1em;
                        }

                        @include media-breakpoint-down(xs) {
                            font-size: 0.875em;
                        }
                    }
                }

                &__image {
                    .SlantedBorder {
                        background-color: var(--primary);
                    }
                }
            }
        }
    }
}
