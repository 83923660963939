﻿@import '../../../../Common/src/scss/pages/_catalog_product.scss';

.catalog__product {
    .breadcrumb-item {
        font-family: var(--tertiary-font);
        font-weight: bold;
        @include font-size(16px);
        letter-spacing: 0.1px;
    }

    &.product__showroom {
        nav > .breadcrumb {
            padding-left: 1rem;
        }

        .ProductView {
            padding: 0;
        }

        .ProductDetails {
            .ProductPrice > p {
                @include font-size(26px);
                font-family: var(--secondary-font);
            }

            > div:not(:first-child) {
                margin-top: 1.5rem;
            }
        }

        .ProductShortDescription {
            p {
                color: $dark;
                margin-top: 1.5em;
                @include font-size(16px);
                font-weight: 300;
                letter-spacing: 1px;
                line-height: 1.5em;

                @include media-breakpoint-down(md) {
                    text-align: center;
                    font-size: 14px;
                }
            }
        }

        .ProductRating {
            display: flex;
            justify-content: center;

            .yotpo.bottomLine.yotpo-small .yotpo-display-wrapper .standalone-bottomline .yotpo-bottomline {
                .rating-star,
                .text-m {
                    color: $dark !important;
                }

                .text-m {
                    margin-left: 0.875rem;
                }
            }
        }
    }
}
