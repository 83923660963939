﻿@import '../../../../Common/src/scss/components/_inputs.scss';

.num-input {
    min-height: 2.5rem;
    display: flex;
    align-items: stretch;
    position: relative; /* without this, the number input buttons are not aligned properly */
}

.NumberInput {
    box-sizing: border-box;
    background: $light;
    margin: 0;
    width: 100%;
    height: auto !important;
    min-height: 2rem;
    font-size: inherit !important;
    text-align: center;
    &.disabled,
    &:disabled,
    &[disabled] {
        background: none;
        color: $gray-medium !important;
        pointer-events: none;
    }
}

input + .NumberInput-btn {
    right: 0;
    left: auto;
}

.NumberInput-btn {
    max-height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    padding: 0;
    margin: 0;
    width: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    height: auto;
    font-size: 0.8em;
    color: $dark;
    &.disabled,
    &:disabled,
    &[disabled] {
        opacity: 0.33;
        pointer-events: none;
    }
    > .Icon {
        display: block;
    }

    @media (hover: hover) {
        &:hover {
            color: var(--primary);
        }
    }
}

.Field > label {
    display: flex;
    > p {
        display: flex;
        font-family: var(--tertiary-font) !important;
        font-size: 1rem;
        letter-spacing: 0.25px;
        line-height: 24px;
        font-weight: 400 !important;
    }
}
