﻿@import '../../../../../Common/src/scss/components/react/OrderHistory.scss';

.OrderHistory {
    .AccordionTable {
        tbody {
            td {
                padding-right: 20px;

                &:last-child {
                    padding-right: 0.3rem;
                }
            }
        }

        @include media-breakpoint-down('md') {
            tbody {
                td {
                    padding-right: 10px;
                    .btn-sm {
                        padding: 7px 20px;
                    }

                    a.tracking {
                        font-family: var(--tertiary-font);
                    }
                }
                td:last-child {
                    padding-right: 10px;
                    .btn-sm {
                        padding: 7px 5px 20px 5px !important;
                    }

                    a.tracking {
                        font-family: var(--tertiary-font);
                    }
                }
            }
        }
    }
}
