﻿@import '../../../../Common/src/scss/sections/_header.scss';

.header__banner {
    text-align: center !important;
    @include font-size(14);
    background: var(--primary);
    text-align: center;
    display: block;
}

.header__breadcrumbs {
    position: absolute;
    z-index: 2;
    margin-top: calc(57px + 1em);
    margin-left: 4em;
    background: none;

    .breadcrumb {
        padding: 0;
        margin: 0;

        .breadcrumb-item {
            font-family: var(--tertiary-font);
            font-weight: bold;
            @include font-size(16px);
            letter-spacing: 0.1px;
        }
    }
}

.header__mast {
    padding: 16px 0px;
    color: $white;
    background-color: $black;
    font-size: 19px;

    .header__user button,
    a.header__login {
        @extend .paragraph-3;
        font-family: var(--tertiary-font);
    }

    .header_user {
        button {
            i.fa-chevron-down {
                font-size: 14px;
            }
        }
    }

    .dropdown-menu:after {
        content: '';
        position: absolute;
        left: 25px;
        top: -10px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 2px 10px 10px 10px;
        border-color: transparent transparent $white transparent;
        z-index: 9999;
    }

    .search {
        font-family: var(--tertiary-font);
        @include font-size(14);
        letter-spacing: 0.25px;
        text-transform: none !important;
    }

    @include media-breakpoint-down(md) {
        padding: 7px 0px;
    }
}
