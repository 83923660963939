﻿.dropdown__drawer {
    background-color: var(--dark);
    color: var(--light);

    & .list-group-item {
        background-color: transparent;
        border: 0;
        flex: 2 2 40%;
    }

    & a {
        background-color: transparent;
        color: $white;
    }
}
