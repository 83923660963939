@import '../../../../Common/src/scss/blocks/_productfacettable.scss';

.productfacettableblock {
    &__Header {
        &__Title {
            font-size: 12rem;
            font-weight: 900;
            color: var(--primary);

            @include media-breakpoint-down(lg) {
                font-size: 10rem;
            }

            @include media-breakpoint-down(md) {
                font-size: 8rem;
            }

            @include media-breakpoint-down(sm) {
                font-size: 5.5rem;
            }
        }

        &__Subtitle {
            font-size: 5rem;
            font-weight: 900;
            color: $gray-mix;

            @include media-breakpoint-down(lg) {
                font-size: 4rem;
            }

            @include media-breakpoint-down(md) {
                font-size: 3rem;
            }

            @include media-breakpoint-down(sm) {
                font-size: 2rem;
            }
        }
    }
}
