@import '../../../../../Common/src/scss/components/react/ProductAccordion.scss';

.ProductAccordion {
    > ul > li {
        border-color: $dark !important;
        color: $dark;
        > a {
            color: $dark !important;
        }
    }
}
