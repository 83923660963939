.LanguageSelector {
    button {
        display: flex;
        flex-direction: row;
        align-items: center;
        &.dropdown-toggle {
            padding: 0.25em 0.5em;
            background: none;
            border: none;
            padding: 0px;
            &::after {
                display: none;
            }
            &[aria-expanded='true'] {
                i.fa {
                    transform: scaleY(-1);
                }
            }
        }
        &.dropdown-item {
            &:hover {
                background-color: color-mix(in srgb, var(--primary), transparent 80%);
            }
            &:focus {
                background-color: var(--primary);
            }
            &:disabled {
                background: var(--primary);
            }
        }

        
        &.dropdown-menu {
            color: var(--dark);
        }
    }
    margin-right: 0.25em;
}
