﻿@use 'sass:math';

.hero__block {
    background-color: transparent;
    margin-bottom: 0px;

    .display-1,
    p {
        margin-bottom: 0px;
    }

    &.style1 {
        .subheader {
            margin-bottom: 11px;
        }

        .btn-lg {
            margin-top: 50px;
        }

        @include media-breakpoint-down(sm) {
            .btn-lg {
                margin-top: 31px;
            }

            .subheader {
                line-height: 0.9em;
            }
        }
    }

    &.style2 {
        .hero_subtitle,
        .hero_title {
            padding-bottom: 30px;
        }

        @include media-breakpoint-down(md) {
            p.hero_subtitle {
                font-size: 14px;
            }

            .hero_button .btn-lg {
                padding: 7px 40px;

                span {
                    font-size: 14px;
                }
            }
        }
    }
}

.hero__content {
    position: relative;

    &--pin-TopCenter {
        transform: translateX(-50%);
    }

    &--pin-TopRight {
        transform: translateX(-100%);
    }

    &--pin-CenterLeft {
        transform: translateY(-50%);
    }

    &--pin-Center {
        transform: translate(-50%, -50%);
    }

    &--pin-CenterRight {
        transform: translate(-100%, -50%);
    }

    &--pin-BottomLeft {
        transform: translateY(-100%);
    }

    &--pin-BottomCenter {
        transform: translate(-50%, -100%);
    }

    &--pin-BottomRight {
        transform: translate(-100%, -100%);
    }

    &--Mobile {
        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    &--Desktop {
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
}

.hero-video {
    .hero-video__video {
        @include media-breakpoint-up('lg') {
            position: absolute;
            left: 0;
            top: 0;
        }

        video {
            object-fit: cover;
        }

        .mute-button,
        .unmute-button {
            background-color: transparent;
            border: none;
            color: $white;
            font-size: 32px;
            z-index: 2;
        }
    }

    &.mobile-position-above {
        .hero-video__video {
            order: 0;
        }

        @include media-breakpoint-down('md') {
            .jumbotron {
                height: auto;
            }
        }
    }

    &.mobile-position-below {
        .hero-video__video {
            order: 1;
        }

        @include media-breakpoint-down('md') {
            .jumbotron {
                height: auto;
            }
        }
    }

    &.mobile-position-within {
        .hero-video__video {
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    @include media-breakpoint-down('md') {
        &.bg-mobile-primary {
            background-color: var(--primary);
        }
        &.bg-mobile-secondary {
            background-color: var(--secondary);
        }
        &.bg-mobile-tertiary {
            background-color: var(--tertiary);
        }
        &.bg-mobile-light {
            background-color: $light;
        }
        &.bg-mobile-dark {
            background-color: $dark;
        }
    }

    @include media-breakpoint-up('lg') {
        &.bg-desktop-primary {
            background-color: var(--primary);
        }
        &.bg-desktop-secondary {
            background-color: var(--secondary);
        }
        &.bg-desktop-tertiary {
            background-color: var(--tertiary);
        }
        &.bg-desktop-light {
            background-color: $light;
        }
        &.bg-desktop-dark {
            background-color: $dark;
        }
    }
}

.hero__line {
    &--font-primary {
        font-family: var(--primary-font);
    }

    &--font-secondary {
        font-family: var(--secondary-font);
    }

    &--font-tertiary {
        font-family: var(--tertiary-font);
    }

    &--font-variant-outlined {
        &.text__color-primary {
            @include outline-text(var(--primary), transparent, 1px);
        }

        &.text__color-secondary {
            @include outline-text(var(--secondary), transparent, 1px);
        }

        &.text__color-tertiary {
            @include outline-text(var(--tertiary), transparent, 1px);
        }

        &.text__color-light {
            @include outline-text($light, transparent, 1px);
        }

        &.text__color-dark {
            @include outline-text($dark, transparent, 1px);
        }
    }

    &--text-style {
        &__title {
            font-size: var(--font-size-title);

            @include media-breakpoint-down('lg') {
                font-size: calc(var(--font-size-title) - (var(--font-size-title) * 0.1));
            }

            @include media-breakpoint-down('md') {
                font-size: calc(var(--font-size-title) - (var(--font-size-title) * 0.2));
            }

            @include media-breakpoint-down('sm') {
                font-size: calc(var(--font-size-title) - (var(--font-size-title) * 0.3));
            }
        }

        &__subtitle {
            font-size: var(--font-size-subtitle);

            @include media-breakpoint-down('lg') {
                font-size: calc(var(--font-size-subtitle) * 0.9);
            }

            @include media-breakpoint-down('md') {
                font-size: calc(var(--font-size-subtitle)  * 0.8);
            }

            @include media-breakpoint-down('sm') {
                font-size: calc(var(--font-size-subtitle) * 0.7);
            }
        }
    }

    a.btn {
        font-size: calc(var(--btn-font-size) * 1.15);

        @include media-breakpoint-down('lg') {
            font-size: var(--btn-font-size);
            padding: 16px 36px;
        }

        @include media-breakpoint-down('md') {
            font-size: calc(var(--btn-font-size)  * 0.85);
            padding: 16px 32px;
        }

        @include media-breakpoint-down('sm') {
            font-size: calc(var(--btn-font-size) * 0.7);
            padding: 16px 30px;
        }
    }

    a.btn-sm{
        font-size: calc(var(--btn-font-size-sm) * 1.15);

        @include media-breakpoint-down('lg') {
            font-size: var(--btn-font-size-sm);
            padding: 7px 24px;
        }

        @include media-breakpoint-down('md') {
            font-size: calc(var(--btn-font-size-sm)  * 0.85);
            padding: 7px 20px;
        }

        @include media-breakpoint-down('sm') {
            font-size: calc(var(--btn-font-size-sm) * 0.7);
            padding: 7px 16px;
        }
    }

    a.btn-lg {
        font-size: calc(var(--btn-font-size-lg) * 1.15);

        @include media-breakpoint-down('lg') {
            font-size: var(--btn-font-size-lg);
            padding: 20px 46px;
        }

        @include media-breakpoint-down('md') {
            font-size: calc(var(--btn-font-size-lg)  * 0.85);
            padding: 20px 42px;
        }

        @include media-breakpoint-down('sm') {
            font-size: calc(var(--btn-font-size-lg) * 0.7);
            padding: 20px 38px;
        }
    }
}

.hero--advanced {
    background-image: var(--desktop-background-image);
    background-size: var(--desktop-background-image-size);
    background-position: var(--desktop-background-position);

    @include media-breakpoint-down(md) {
        background-image: var(--mobile-background-image, var(--desktop-background-image));
        background-size: var(--mobile-background-image-size, var(--desktop-background-image-size));
        background-position: var(--mobile-background-position, var(--desktop-background-position));
    }

    @for $i from 1 through $grid-columns {
        @each $breakpoint, $container-max-width in $container-max-widths {
            @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
                .content-width-#{$i} {
                    width: math.div($i * $container-max-width, $grid-columns);
                }
            }
        }
    }

    .hero-video__video {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;

        &--mobile-hidden {
            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        video {
            position: absolute;

            object-fit: cover;

            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;

            transform: translate(-50%, -50%);

            &::-webkit-media-controls {
                display: none !important;
            }
        }

        .mute-button,
        .unmute-button {
            background-color: transparent;
            border: none;
            color: $white;
            font-size: 32px;
            z-index: 2;
        }
    }

    .hero__block {
        height: auto;
        min-height: var(--desktop-min-height);

        @include media-breakpoint-down(md) {
            min-height: var(--mobile-min-height, var(--desktop-min-height));
        }
    }
}
