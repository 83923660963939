﻿@import '../../../../../Common/src/scss/components/react/ProductRating.scss';

.ProductRating {
    .yotpo.bottomLine.yotpo-small {
        @include media-breakpoint-up(md) {
            min-width: 100%;
        }

        .yotpo-display-wrapper {
            .standalone-bottomline {
                .yotpo-bottomline {
                    justify-content: space-between;
                    min-width: 100%;
                    @include media-breakpoint-down(md) {
                        flex-wrap: wrap;

                        .yotpo-stars {
                            padding-right: 10px;
                        }
                    }

                    .rating-star {
                        color: var(--primary);
                    }

                    .text-m {
                        color: var(--secondary) !important;
                        font-family: var(--tertiary-font);
                        font-size: 0.875rem;
                        font-style: inherit;
                    }
                }
            }
        }
    }
}
