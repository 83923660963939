﻿@import '../../../../../Common/src/scss/components/react/ProductBadge.scss';

.ProductBadge {
    font-size: 14px;
    .badge {
        padding: 0.5714em 0.6875em;
        font-weight: 900;
        letter-spacing: 0;
        line-height: calc(1em - 1px);
    }
}
