﻿@import '../../../../../Common/src/scss/components/react/ProductSearchBar.scss';

.ProductSearchBar {
    padding-top: 9rem;
    padding-bottom: 1rem;
    border-bottom: 0.2em solid $gray-dark;

    @include media-breakpoint-down(md) {
        padding-top: 7.75rem;
    }
    @include media-breakpoint-down(xs) {
        padding-top: 6.5rem;
    }

    &__bar {
        border: 1px solid color-mix(in srgb, var(--primary), transparent 80%);
        background-color: $gray-dark;

        input {
            color: $light;
            letter-spacing: 0.1em !important;
            font-weight: normal;
        }
    }

    &__icon {
        color: $light;
        font-size: 1rem;

        @media (hover: hover) {
            &:hover {
                color: $light;
            }
        }
    }

    .ProductSearchBarDrawer {
        background-color: $dark;

        &__results {
            &__links {
                a {
                    font-size: 0.875rem;
                    letter-spacing: 0.14285em;
                    line-height: 1.357em;
                    color: var(--primary);
                }
            }

            &__result {
                color: $light;

                &__title {
                    font-size: 1rem;
                    letter-spacing: 0.0625em;
                    line-height: 1.2em;
                    font-family: var(--tertiary-font);
                    > a {
                        color: $light !important;
                        width: 100%;
                        text-align: center;
                        display: block;

                        @media (hover: hover) {
                            &:hover {
                                color: var(--primary) !important;
                            }
                        }
                    }
                }

                &__price {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;

                    > p {
                        font-size: 1.2rem;
                        text-align: center;
                        color: $light;
                        font-family: var(--secondary-font);

                        + p {
                            margin-left: 1em;
                        }
                    }
                    .strike {
                        color: $gray-secondary;
                    }

                    .ProductPrice__reduced-price {
                        color: var(--primary);
                    }
                }
            }
        }

        &__links {
            &__link {
                &__title {
                    text-transform: uppercase;
                    padding-bottom: 1.1785em;
                    margin-bottom: 1.1785em;
                    border-bottom: 1px solid color-mix(in srgb, var(--primary), transparent 80%);
                    letter-spacing: 0.1314em;
                }

                a,
                p {
                    font-size: 0.7291em;
                    color: $white;
                    letter-spacing: 1px;

                    .Icon {
                        font-size: 1.1428em;
                    }
                }
            }
        }
    }
}
