﻿@import '../../../../../Common/src/scss/components/react/CatalogQuickView.scss';

.CatalogQuickView {
    border-top: 2px solid black;
    border-bottom: 2px solid black;

    &__details {
        h5 {
            @include font-size(30px);
            font-family: var(--primary-font);
            font-weight: 900;
            letter-spacing: 0;
            line-height: 1em;
            color: $dark;
        }
    }

    &__prices {
        > div:not(.CatalogQuickView__stock) {
            @include font-size(19px);
            font-family: var(--tertiary-font);
        }
    }

    &__stock {
        @include font-size(14px);
        font-family: var(--tertiary-font);
        padding-bottom: 0.2em;
    }

    &__links {
        justify-content: center;
    }
}
