﻿@import '../../../../../Common/src/scss/components/react/CatalogGrid.scss';

$border-color: #e1e1e1;

.CatalogGrid {
    /* First row is product list container */
    > .row:first-child > * {
        outline: 1px solid $border-color !important;

        @media (hover: hover) {
            &:hover {
                outline: 1px solid $border-color !important;
            }
        }
    }
}
