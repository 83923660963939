﻿@import '../../../../Common/src/scss/components/_navigation_main.scss';
.navigation__main {
    background-color: $gray-dark;
    .menucta__links .menulink_image {
        justify-content: flex-end !important;
        > img {
            max-width: 100% !important;
            height: auto !important;
            margin: 0 !important;
        }
    }
    .dropdown-item {
        a {
            font-size: 19px;
            letter-spacing: 2px;
            color: white !important;
        }
        @include media-breakpoint-down('lg') {
            min-height: 143px;
        }
    }
    .navbar-nav .dropdown-menu {
        top: auto;
    }

    &.navbar-dark {
        .navbar-nav {
            .nav-link {
                color: var(--primary);
            }
        }
    }
    @include media-breakpoint-up(md) {
        @media (hover: hover) {
            .nav-item:hover .nav-link {
                background-color: $white;
                color: $black;
            }
        }
        .nav-item.show,
        .nav-item:focus {
            .nav-link {
                background-color: $white;
                color: $black;
            }
        }
    }
    @include media-breakpoint-down(md) {
        .navbar-collapse,
        .navigation__main {
            background: $black;
        }
        @media (hover: hover) {
            .nav-item:hover .nav-link {
                color: var(--primary);
            }
        }
        .nav-item.show,
        .nav-item:focus {
            .nav-link {
                color: var(--primary);
            }
        }
        .navbar-collapse {
            a.nav-link {
                color: var(--primary);
                padding: 16px 0px !important;
                margin: 0px 0.6em;
                span {
                    line-height: 20px;
                }
                i {
                    margin-bottom: 4px;
                }
            }
            h5 {
                color: $white;
            }
            .menucta__links__mobile {
                img {
                    filter: invert(1);
                }
            }
        }
    }
}
