@import '../../../../../Common/src/scss/components/react/CatalogExploreGrid.scss';

.CatalogExploreGrid {
    &__grid {
        &__card {
            &__title {
                > * {
                    color: var(--primary);
                    text-shadow: 1px 1px $gray-dark;
                }
            }
            &__description > p {
                font-size: 1rem;
            }
            &__bottom-row {
                > button.btn {
                    color: var(--secondary) !important;
                }
            }
        }
    }
}
