@import '../../../../../Common/src/scss/components/react/CatalogDetailGrid.scss';

.CatalogDetailGrid {
    &__grid {
        &__card {
            &__title {
                > * {
                    color: var(--secondary);
                }
            }
        }
    }
    .NotificationSubscription__notify-link {
        font-size: 0.875rem;
    }
}
