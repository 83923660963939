﻿@import '../../../../../Common/src/scss/components/react/CatalogQuery.scss';

.CatalogQuery {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2em;

    > section {
        display: flex;
        align-items: center;
    }

    &__filters {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__label {
        margin-right: 1em;
        font-family: var(--tertiary-font);
        @include font-size(16px);
    }

    &__filter {
        border-radius: 0.5em;
        padding: 0.5em 0.75em;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: white;
        background: black;
        @include font-size(14px);

        > .btn-clear {
            margin-right: 0.75em;
            cursor: pointer;
        }
    }

    .btn-clear-all {
        > span {
            font-family: var(--tertiary-font);
            @include font-size(14px);
            font-weight: bold;
            letter-spacing: 0.3px;
            text-transform: none;
        }
    }
}
