﻿@import '../../../../../Common/src/scss/components/react/ProductGridCell.scss';

.ProductGridCell {
    &__input {
        .num-input {
            max-height: 100%;
        }
        .btn-primary {
            line-height: 2.5em;
        }
        &.oos {
            .btn:not(.NotificationSubscription__notify-link) {
                background: $gray-medium !important;
                color: $dark !important;
                opacity: 1 !important;
                pointer-events: none;
                border: none !important;
                line-height: 0.67em;
            }
        }
        .NumberInput-btn {
            font-size: 12px !important;
            position: absolute;
            padding: 12px 2px;
        }
        .NumberInput {
            font-size: 14px !important;
            height: 2.625rem;
        }
    }
    &__name,
    &__price > * {
        font-size: 19px !important;
        @include media-breakpoint-down(sm) {
            font-size: 16px !important;
        }
        font-family: var(--tertiary-font);
        font-weight: 600;
        line-height: 1em;
        color: $dark;
    }
    &__top-bar {
        > .ProductBadge .badge {
            font-size: 0.8rem;
        }
    }

    .ProductGallery__Modal {
        .btn {
            &.left,
            &.right {
                color: $dark;
            }
        }
    }
}
