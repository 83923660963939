﻿@import '../../../../../Common/src/scss/components/react/ProductReviews.scss';

.yotpo.yotpo-main-widget {
    .yotpo-label-container,
    .yotpo-logo-title,
    .yotpo-icon-yotpo-logo,
    .yotpo-icon-seperator,
    .yotpo-reviews-filters {
        display: none !important;
    }

    #yotpo_input_question_content {
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 1px;
        line-height: 20px;
    }

    #yotpo_input_question_content:focus {
        border-color: var(--primary) !important;
    }

    div.bottom-line-items {
        display: flex;
        justify-content: flex-start;

        @include media-breakpoint-down(sm) {
            display: table;
            margin: 0 auto;
            text-align: center !important;
        }
    }

    div.bottom-line-items > span.avg-score {
        color: $gray-secondary !important;
        font-family: var(--tertiary-font) !important;
        font-size: $font-size-base;
        line-height: 1.625rem !important;
        padding: 0px 5px !important;
        width: unset !important;
        height: unset !important;

        @include media-breakpoint-down(sm) {
            width: unset !important;
            padding-right: 10px !important;
            padding-top: unset;
            height: 1.75rem;
            text-align: right;
            line-height: unset;
        }
    }

    div.bottom-line-items > span.yotpo-filter-stars.rating-stars-container {
        color: var(--primary) !important;
        font-family: var(--tertiary-font) !important;
        font-size: $font-size-base !important;
        height: 1.75rem !important;
        line-height: 1.625rem !important;
        padding-right: 5px !important;

        @include media-breakpoint-down(sm) {
            display: inline-flex;
            float: unset;
            padding-right: 10px !important;
            padding-top: unset;
        }
    }

    .reviews-qa-labels-container {
        margin-left: unset;
        position: unset;
        display: unset;
        align-items: center;

        @include media-breakpoint-down(sm) {
            display: inline-flex;
            float: unset;
            height: 1.75rem !important;
            padding-top: unset;
        }
    }

    .reviews-qa-label {
        color: $gray-secondary !important;
        font-family: var(--tertiary-font) !important;

        @include media-breakpoint-down(sm) {
            display: flex;
            justify-content: center;
            width: unset !important;
        }
    }

    div.bottom-line-items > span.yotpo-filter-stars.rating-stars-container > span.yotpo-icon,
    div.yotpo-first-review > div.yotpo.first-review-stars {
        color: var(--primary) !important;
        font-family: var(--tertiary-font) !important;
        font-size: $font-size-base !important;
        height: 1.625rem !important;
        line-height: 1.625rem !important;
    }

    .review-question-header {
        color: $dark !important;
        font-family: var(--primary-font) !important;
        font-size: $h4-font-size !important;
        font-weight: bold !important;
        padding-right: 0.625rem !important;
        text-transform: uppercase !important;

        @include media-breakpoint-down(sm) {
            display: block;
            justify-content: center;
            padding-bottom: 1.25rem;
        }
    }

    .write-first-review-button,
    .write-first-question-button {
        background: var(--primary) !important;
        border: 1px solid var(--primary) !important;
        color: $dark !important;
        height: 2.25rem !important;
        width: 17.1875rem !important;
        font-family: var(--secondary-font) !important;
        font-size: 1rem !important;
        font-weight: normal !important;
    }

    button.write-question-review-button {
        background: var(--primary) !important;
        border-color: var(--primary) !important;
        height: 2.25rem !important;
        text-transform: uppercase !important;
        width: 10.625rem !important;

        span.yotpo-icon {
            color: $dark !important;
        }
    }

    button.write-question-button {
        margin: unset !important;
    }

    div.new-yotpo-small-box {
        border-color: var(--primary) !important;

        li.yotpo-nav-tab > div.yotpo-nav-wrapper > span {
            font-family: var(--primary-font) !important;
        }
    }

    .yotpo-submit.primary-color-btn {
        background: var(--primary) !important;
        border: 1px solid var(--primary) !important;
        color: $dark !important;
        font-family: var(--secondary-font) !important;
        font-size: 1rem !important;
        height: 2.25rem !important;
        width: 10.625rem !important;

        &:hover {
            background: var(--primary) !important;
            color: $dark;
        }
    }

    .write-question-review-button-text {
        background: var(--primary) !important;
        color: $dark !important;
        font-family: var(--secondary-font) !important;
        font-size: 1rem !important;
        font-weight: normal !important;
    }

    div.write-review.yotpo-regular-box {
        border-color: var(--primary) !important;
        margin-top: 10px !important;
    }

    span.reviews-amount {
        font-family: var(--tertiary-font) !important;
    }

    span.yotpo-icon-profile {
        background: var(--primary) !important;

        span.yotpo-user-letter {
            color: $dark !important;
        }

        span.yotpo-icon-circle-checkmark {
            color: $dark !important;
        }
    }

    span.yotpo-review-date {
        font-family: var(--tertiary-font) !important;
    }

    div.yotpo-store-owner {
        span.yotpo-icon-profile {
            background: $dark !important;

            span.yotpo-icon-store {
                color: var(--primary) !important;
            }

            span.yotpo-icon-circle-checkmark {
                color: var(--primary) !important;
            }
        }
    }

    .yotpo-nav-wrapper {
        border-color: var(--primary) !important;
    }

    span.yotpo-user-name {
        font-family: var(--tertiary-font) !important;
        font-size: 1rem !important;
        line-height: 1.5rem !important;
    }

    span.yotpo-user-title {
        font-family: var(--tertiary-font) !important;
        font-size: $font-size-sm !important;
        line-height: 1.5rem !important;
    }

    .footer-additional-actions {
        @include media-breakpoint-down(md) {
            height: 1.625rem !important;
        }
    }

    div.yotpo-grouping-reference {
        margin-top: 5px !important;
        width: 18rem;
    }

    span.yotpo-review-date {
        @include media-breakpoint-down(md) {
            line-height: 1.625rem !important;
            vertical-align: middle;
        }
    }

    .yotpo-review-wrapper > .content-review,
    .yotpo-review-wrapper > .content-review > .rest-content-collapsed {
        color: $dark !important;
        font-family: var(--tertiary-font) !important;
        font-size: $font-size-sm !important;
        letter-spacing: $letter-spacing-1 !important;
        line-height: 1.25rem !important;

        .yotpo-read-more {
            color: $gray-secondary !important;
        }
    }

    .yotpo-main > .content-title {
        color: $dark !important;
        font-family: var(--primary-font) !important;
        font-size: 1rem !important;
        line-height: 1.2rem !important;
    }

    span.yotpo-icon-share {
        font-family: var(--tertiary-font) !important;
        font-size: $font-size-sm !important;
        letter-spacing: $letter-spacing-2 !important;
        line-height: 1.5rem !important;
    }

    div.yotpo-helpful {
        span.label-helpful {
            //font-family: var(--tertiary-font) !important;
            //font-size: $font-size-sm !important;
            //letter-spacing: $letter-spacing-1 !important;
            line-height: 1.25rem !important;
        }

        span.vote-sum {
            font-family: var(--tertiary-font) !important;
            font-size: 1rem !important;
            letter-spacing: $letter-spacing-1 !important;
            line-height: 1.5rem !important;
        }

        span.yotpo-icon {
            color: var(--primary) !important;
        }
    }

    div.transparent-color-btn {
        border: unset !important;
        background: transparent !important;
    }

    span.yotpo-action > span.y-label {
        //font-family: var(--tertiary-font) !important;
        //font-size: 1px !important;
        line-height: 1.25rem !important;
    }

    div.yotpo-questions {
        div.content-question {
            color: $dark !important;
            font-family: var(--tertiary-font) !important;
            font-size: $font-size-sm !important;
            letter-spacing: $letter-spacing-1 !important;
            line-height: 1.25rem !important;
            font-weight: normal !important;
        }

        div.yotpo-comments-box {
            border-color: var(--primary) !important;
        }
    }

    div.yotpo-syndication-reference {
        float: left;
        margin-top: 0 !important;
        padding-top: 0 !important;
        width: 10rem;

        @include media-breakpoint-down(md) {
            height: 1.625rem !important;
        }

        span::before {
            color: var(--primary) !important;
            content: '|' !important;
            font-family: var(--tertiary-font) !important;
            font-size: 0.875rem !important;
            letter-spacing: 1px !important;
            line-height: 1.25rem !important;
            padding: 0 0.33em;
        }

        span {
            font-family: var(--tertiary-font) !important;
            font-size: 0.875rem !important;
            line-height: 1.5rem !important;

            @include media-breakpoint-down(md) {
                line-height: 1.625rem !important;
                vertical-align: middle;
            }
        }

        .syndication-reference-link > img {
            height: 22px !important;
            width: 38px !important;
        }
    }

    div.yotpo-pager > a.yotpo-icon {
        color: $gray-secondary !important;
    }

    textarea.yotpo-text-box {
        border: 2px solid $dark !important;

        &:focus {
            border-color: var(--primary) !important;
        }
    }

    &.yotpo-small .yotpo-review .yotpo-helpful {
        display: flex;
        align-items: center;
    }
}
