﻿@import '../../../../Common/src/scss/blocks/_product_prostaff_quote.scss';

.productProstaffQuoteBlock {
    padding-left: 0px !important;
    padding-right: 0px !important;

    img {
        max-width: 250px;
        max-height: 250px;
    }

    p.link-icon {
        float: left;
        width: 8%;
        line-height: normal !important;
    }

    p.link-title {
        float: left;
        width: 90%;
        line-height: normal !important;
    }
}

.block.productprostaffquoteblock {
    padding: 0px !important;
}
