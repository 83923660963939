@import '../../../../../Common/src/scss/components/react/CatalogSimpleGrid.scss';

.CatalogSimpleGrid {
    &__grid {
        &__card {
            &--selected {
                &::after {
                    border-top-color: $gray-secondary;
                }
            }

            &__details {
                &__title {
                    > * {
                        color: $dark;
                    }
                }
                &__description > p {
                    font-size: 1rem;
                }
            }
        }

        &__quickview {
            &__title {
                color: $dark !important;
            }

            &__input {
                .btn {
                    padding-top: 0.33em !important;
                }
            }
        }
    }

    input.NumberInput {
        color: $dark !important;
        font-size: 0.875rem !important;
    }
}
