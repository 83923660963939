@import '../../../../../Common/src/scss/components/react/NotificationSubscription.scss';

.NotificationSubscription {
    &__notify-link {
        font-family: var(--tertiary-font);
    }
    &__popup {
        background: $gray-ghost;

        &__message.error {
            color: $dark;
        }
    }
}
